import React from "react";
import "../styles/privacy.css";
function Privacy() {
  return (
    <div className="privacy-container">
      <h2>Privacy Policy</h2>
      <p>
        Musicplace is a mobile app where users can discover music and connect
        with friends. When you create an account, you’re able to listen to 30
        second song snippets recommended to you based on what music you like.
        Because Musicplace is designed for younger people, we care deeply about
        safety and user privacy. We try to limit the data we collect to what is
        necessary to use the app and connect with friends.
      </p>
      <p>
        When you sign up for an account on Musicplace, you are asked to enter
        your phone number and display name. This data is used to identify you to
        other Musicplace users and makes it easy for your friends to find your
        account on the service.
      </p>
      <p>
        If you connect your Musicplace account with Spotify, Musicplace uses
        this connection in the following ways:
      </p>
      <ul>
        <li>
          Allows you to add and remove songs from your liked songs library in
          Spotify.
        </li>
        <li>
          Allows you to add and remove songs from your playlists in Spotify.
        </li>
        <li>
          Allows you to search for songs to start a radio, where you can listen
          to 30 second song snippets based off of one track in particular.{" "}
        </li>
        <li>
          Allows you to access your saved tracks, playlists, and albums so you
          may post songs to your profile in the future.
        </li>
        <li>
          Allows you to access the artists you follow so we may serve you their
          new releases in the future.
        </li>
        <li>
          Allows us to access your top songs to recommend you content based on
          your listening history. Your top songs are updated daily to ensure
          you’re listening to songs that best reflect your listening
          preferences. We also display this information on your profile so your
          friends can get to know your listening preferences better.
        </li>
      </ul>
      <p>
        If you would like to disconnect your Musicplace account from Spotify,
        you may do so within the application (profile -> settings -> disconnect
        from Spotify). After disconnecting, Musicplace, and/or your user, no
        longer has access to any of the functionality mentioned above.
      </p>
      <p>
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we'll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorized access, disclosure, copying, use or modification.
      </p>
      <p>
        Our app may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and practices of these
        sites, and cannot accept responsibility or liability for their
        respective privacy policies.
      </p>
      <p>
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services.
      </p>
      <p>Happy listening :)</p>
    </div>
  );
}

export default Privacy;
