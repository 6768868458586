import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
