import React from "react";
import Logo from "../logo.svg";
import Apple from "../apple.svg";
import "../styles/home.css";
import { Link } from "react-router-dom";

function Home() {
  function handleDownload() {
    window.open(
      "https://apps.apple.com/us/app/musicplace/id1642575540",
      "_blank"
    );
  }

  return (
    <div className="container">
      <img className="logo" alt="logo" src={Logo}></img>
      <div className="main-container">
        <p className="desc">
          Discover music and connect with friends. All in one place.
        </p>
      </div>
      <div className="ios-btn" onClick={handleDownload}>
        <p className="ios-text">Download on</p>
        <img alt="apple-logo" src={Apple}></img>
      </div>
      {/* <div className="iphone-container"> */}
      <img
        className="iphone"
        alt="iphone"
        src="https://firebasestorage.googleapis.com/v0/b/musicplace-website.appspot.com/o/PLEASE.png?alt=media&token=268ea412-1f74-4808-967d-d1a323248184"
      />
      {/* </div> */}
      <div className="footer">
        <p className="copyright">© Musicplace 2023</p>
        <p id="dot">•</p>
        <Link style={{ textDecoration: "none" }} to={"/privacy"}>
          <p>Privacy Policy</p>
        </Link>
      </div>
    </div>
  );
}

export default Home;
